import { memo } from 'react';

import { useStepByStep } from '../../../context/StepByStepContext';

import { StepItem } from '../StepItem/StepItem';

import { type ICategoryTreeRecord } from '../../../../../../../modules/budget/transformations/form/@interfaces';

interface IAllCategoriesStep {
  handleNextStep: (category: ICategoryTreeRecord) => void;
}

const AllCategoriesStepComponent = ({ handleNextStep }: IAllCategoriesStep) => {
  const { steps } = useStepByStep();

  const allCategories = steps[steps.length - 1].map((category) => {
    return (
      <StepItem
        key={category.id}
        category={category}
        handleNextStep={handleNextStep}
      />
    );
  });

  return allCategories;
};

export const AllCategoriesStep = memo(AllCategoriesStepComponent);
