/* eslint-disable no-unused-vars */
import React from 'react';

import styles from './ChipLink.module.scss';

export interface IChipLinkProps {
  /**
   * Chips id. Used to identify the label and for acessibility purposes
   */
  id: number;
  /**
   * Chips label
   */
  text: string;
  /**
   * Chips link
   */
  link: string;
  /**
   * Chips target
   */
  target: string;
}

const ChipLink = ({ id, text, link, target }: IChipLinkProps) => {
  return (
    <a className={`chip`} href={link} target={target}>
      <p className={`chip__text ${styles.chip__text}`} id={String(id)}>
        {text}
      </p>
    </a>
  );
};

export default ChipLink;
