import { useEffect, useState } from 'react';

export type TIsShouldResetCountdown = {
  canReset: boolean;
  onReset: () => void;
};

interface IUseCountDownProps {
  initialCount: number;
  onCountdownIsOverExecute: () => void;
  isShouldResetCountdown?: TIsShouldResetCountdown;
}

export function useCountdown({
  initialCount,
  onCountdownIsOverExecute,
  isShouldResetCountdown = null,
}: IUseCountDownProps) {
  const [countdownActiveTime, setCountdownActiveTime] = useState(initialCount);
  const [shouldShowCountdown, setShouldShowCountdown] = useState(true);

  useEffect(() => {
    if (isShouldResetCountdown && isShouldResetCountdown.canReset === true) {
      setShouldShowCountdown(true);

      isShouldResetCountdown.onReset();
    }
  }, [initialCount, isShouldResetCountdown]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (shouldShowCountdown && countdownActiveTime > 0) {
        setCountdownActiveTime((oldTimeValue) => oldTimeValue - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countdownActiveTime, shouldShowCountdown]);

  useEffect(() => {
    if (countdownActiveTime === 0) {
      onCountdownIsOverExecute();

      const interval = setInterval(() => {
        setShouldShowCountdown(false);
        setCountdownActiveTime(initialCount);
      }, 1200);

      return () => clearInterval(interval);
    }
  }, [countdownActiveTime, initialCount, onCountdownIsOverExecute]);

  return {
    countdownActiveTime,
    shouldShowCountdown,
  };
}
