import { TextField } from 'darrius';
import React, {
  type ChangeEvent,
  type InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';

import styles from './PinInput.module.scss';

interface IPinInputProps extends InputHTMLAttributes<HTMLInputElement> {
  initialValue?: string;
  pinValidation: {
    isInvalid: boolean;
    errorMessage: string;
  };
  onSubmitPin: (pin: string) => void;
}

const PIN_LIMIT = 4;

const PinInput = ({
  initialValue = '',
  pinValidation,
  onSubmitPin,
  ...props
}: IPinInputProps) => {
  const [pin, setPin] = useState(() => {
    if (initialValue && initialValue.length === PIN_LIMIT) {
      return initialValue;
    }

    return '';
  });
  const [oldPinValue, setOldPinValue] = useState('');

  const { errorMessage, isInvalid } = pinValidation;

  const pinHasError = isInvalid && errorMessage;

  const handlePinChange = (event: ChangeEvent<HTMLInputElement>) => {
    const getPin = event.target.value.slice(0, PIN_LIMIT);

    setPin(getPin);
  };

  useEffect(() => {
    if (pin.length === PIN_LIMIT && pin !== oldPinValue) {
      setOldPinValue(pin);
      onSubmitPin(pin);
    }
  }, [oldPinValue, onSubmitPin, pin]);

  return (
    <TextField
      id="pin"
      pattern="[0-9]*"
      inputMode="numeric"
      autoComplete="one-time-code"
      className={styles.pinInput__container}
      onChange={handlePinChange}
      value={pin}
      maxLength={PIN_LIMIT}
      errorMessage={pinHasError}
      {...props}
    />
  );
};

export default PinInput;
