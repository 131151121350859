const flattenArray = (array: unknown[]) => [].concat(...array);

export const getPhone = (data: unknown): string => {
  const fields = Object.values(data).map((item) =>
    [].concat(item).filter((field) => field.type === 'phone')
  );

  const [phone] = flattenArray(fields);

  return phone ? phone.value : 'no phone';
};
