import {
  useStepByStep,
  type TCategoryStepByStepForm,
} from '../../../context/StepByStepContext';

import { AllCategoriesStep } from '../AllCategoriesStep/AllCategoriesStep';
import { FilteredStep } from '../FilteredStep/FilteredStep';
import { NotFoundStep } from '../NotFoundStep/NotFoundStep';

interface IStepByStepProps {
  slug: string;
  handleNextStep: (category: TCategoryStepByStepForm) => void;
}

export function Steps({ slug, handleNextStep }: IStepByStepProps) {
  const { categoryIsNotFound, categoriesSearched } = useStepByStep();

  if (categoryIsNotFound) {
    return (
      <ul>
        <NotFoundStep slug={slug} />
      </ul>
    );
  }

  const hasFilteredCategories = categoriesSearched.length > 0;

  if (hasFilteredCategories) {
    return (
      <ul>
        <FilteredStep handleNextStep={handleNextStep} />
      </ul>
    );
  }

  return (
    <ul>
      <AllCategoriesStep handleNextStep={handleNextStep} />
    </ul>
  );
}
