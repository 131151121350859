import axios, { type AxiosRequestConfig, type AxiosPromise } from 'axios';

import http from 'http';
import https from 'https';

export function axiosInstance<T = unknown>(
  requestOptions: AxiosRequestConfig,
  withCredentials = true
): AxiosPromise<T> {
  const axiosCreateInstance = axios.create({
    timeout: 20000,

    // keepAlive pools and reuses TCP connections, so it's faster
    httpAgent: new http.Agent({ keepAlive: false }),
    httpsAgent: new https.Agent({ keepAlive: false }),

    // follow up to 10 HTTP 3xx redirects
    maxRedirects: 10,

    // cap the maximum content length we'll accept to 50MBs, just in case
    maxContentLength: 50 * 1000 * 1000,
    withCredentials,
  });

  return axiosCreateInstance(requestOptions) as AxiosPromise<T>;
}

export type TMockedAxiosInstance<T> = jest.MockedFunction<
  (requestOptions: AxiosRequestConfig) => AxiosPromise<T>
>;

export default { axiosInstance };
