import { memo } from 'react';

import { useStepByStep } from '../../../context/StepByStepContext';

import { StepItem } from '../StepItem/StepItem';

import { type ICategoryTreeRecord } from '../../../../../../../modules/budget/transformations/form/@interfaces';

interface IFilteredStep {
  handleNextStep: (category: ICategoryTreeRecord) => void;
}

const FilteredStepComponent = ({ handleNextStep }: IFilteredStep) => {
  const { categoriesSearched } = useStepByStep();

  const filteredCategories = categoriesSearched.map((category) => {
    return (
      <StepItem
        key={category.id}
        category={category}
        handleNextStep={handleNextStep}
      />
    );
  });

  return filteredCategories;
};

export const FilteredStep = memo(FilteredStepComponent);
