export const DEFAULT_TITLE = 'Mensagens | GetNinjas';

export const getFlashTitle = (notificationCount: number | undefined | null) => {
  if (!notificationCount) {
    return null;
  }

  if (notificationCount >= 1) {
    return `(${notificationCount}) ${DEFAULT_TITLE}`;
  }

  return null;
};
