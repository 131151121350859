import { memo, useEffect } from 'react';

import { triggerEvent } from '../../../../../../../utils';

import { useStepByStep } from '../../../context/StepByStepContext';

import { StepItem } from '../StepItem/StepItem';

interface INotFoundStep {
  slug: string;
}

const NotFoundStepComponent = ({ slug }: INotFoundStep) => {
  const { searchBarValue } = useStepByStep();

  useEffect(() => {
    triggerEvent('step_by_step_form-input_not_found', {
      slug,
      value: searchBarValue,
    });
  }, [searchBarValue, slug]);

  return <StepItem isNotFound />;
};

export const NotFoundStep = memo(NotFoundStepComponent);
