import { type ReactNode } from 'react';
import classnames from 'classnames';

import styles from './FlatCardBody.module.scss';

export interface IFlatCardBodyProps {
  /**
   * Card content
   */
  children: ReactNode;
  /**
   * Card classname
   */
  className?: string;
}

export const FlatCardBody = ({ children, className }: IFlatCardBodyProps) => {
  const classNames = classnames(
    `${styles['flat-card-body']} flat-card-body ${className}`
  );

  return <div className={classNames}>{children}</div>;
};
