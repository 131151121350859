import {
  ToastContainer,
  toast,
  type TypeOptions,
  type Theme,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import styles from './Toast.module.scss';

interface IShowToast {
  id?: string;
  message: string;
  type: TypeOptions;
}

interface ICloseButtonProps {
  closeToast: (e: React.MouseEvent<HTMLElement>) => void;
  type: TypeOptions;
  ariaLabel?: string;
  theme: Theme;
}

interface IToastProps {
  closeButtonText?: string;
}

export const showToast = ({ message, type, id }: IShowToast) => {
  toast(message, {
    type,
    toastId: id,
  });
};

const Toast = ({ closeButtonText = 'Ok' }: IToastProps) => {
  const closeButton = ({ closeToast }: ICloseButtonProps) => (
    <button type="button" onClick={closeToast}>
      {closeButtonText}
    </button>
  );

  return (
    <ToastContainer
      className={styles.toast}
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      closeButton={closeButton}
    />
  );
};

export default Toast;
