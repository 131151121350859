import { type MouseEventHandler } from 'react';
import Image from 'next/image';

import { imgPrefix } from '../../../../utils';

type TClickDirection = 'prev' | 'next';

interface IArrowButtonProps {
  type: TClickDirection;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const ArrowButton = ({ type, onClick }: IArrowButtonProps) => (
  <button
    type="button"
    className={`carousel__${type}`}
    aria-hidden="true"
    tabIndex={-1}
    onClick={onClick}
  >
    <Image
      src={imgPrefix('/images/shared/arrow.svg')}
      alt="arrow icon"
      width="20"
      height="13"
    />
  </button>
);

export default ArrowButton;
