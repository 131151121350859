import { useRef, useEffect, type ReactChild } from 'react';
import classnames from 'classnames';

interface IOverflowElementProps {
  className?: string;
  children: ReactChild;
  ammount: {
    sm: number;
    md: number;
    lg: number;
  };
}

const OverflowElement = ({
  className = '',
  children,
  ammount,
}: IOverflowElementProps) => {
  const classNames = classnames('overflow-element', className);

  const overflowElement = useRef(null);

  useEffect(() => {
    overflowElement.current.style.setProperty(
      '--overflow-ammount-sm',
      `${-ammount.sm}rem`
    );

    overflowElement.current.style.setProperty(
      '--overflow-ammount-md',
      `${-ammount.md}rem`
    );

    overflowElement.current.style.setProperty(
      '--overflow-ammount-lg',
      `${-ammount.lg}rem`
    );
  }, [ammount.lg, ammount.md, ammount.sm]);

  return (
    <div ref={overflowElement} className={classNames}>
      {children}
    </div>
  );
};

export default OverflowElement;
