import Image from 'next/image';

import { Banner, Col, Paragraph, Row } from 'darrius';

import { imgPrefix } from '../../../../utils';

import { type TDialogInternalLoadingProps } from './@interfaces';

export function DialogInternalLoading({ title }: TDialogInternalLoadingProps) {
  return (
    <Row className="center-sm">
      <Col className="col-sm-12">
        <Image
          src={imgPrefix('/images/shared/get-ninjas-spinner.gif')}
          alt="Um spinner de carregamento"
          title="Um spinner de carregamento"
          height={77}
          width={77}
        />
        <Banner>
          <Paragraph title={title} size="2" />
        </Banner>
      </Col>
    </Row>
  );
}
