import { useEffect, useState } from 'react';
import Image from 'next/image';

import { imgPrefix } from '../../../utils';

import styles from './FloatButton.module.scss';

interface IFloatButtonProps {
  valueToScroll?: number;
}

const FloatButton = ({ valueToScroll = 600 }: IFloatButtonProps) => {
  const [classname, setClassname] = useState(styles['float-button__hide']);

  useEffect(() => {
    function scrollHandler() {
      if (window.scrollY > valueToScroll) {
        setClassname(styles['float-button']);
      } else {
        setClassname(styles['float-button__hide']);
      }
    }

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [valueToScroll]);

  function handleScrollTo() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <button className={classname} onClick={handleScrollTo}>
      <Image
        src={imgPrefix('/images/shared/arrow-white.svg')}
        alt="arrow"
        width={20}
        height={13}
      />
    </button>
  );
};

export default FloatButton;
