interface IResponsiveImage {
  sm: string;
  md: string;
  lg: string;
}

export const imgPrefix = <T>(img: T): T => {
  if (typeof img === 'string') {
    return `${process.env.NEXT_PUBLIC_ASSETS_PREFIX}${img}` as T;
  }

  const imgObj = img as IResponsiveImage;

  return {
    sm: imgPrefix(imgObj.sm),
    md: imgPrefix(imgObj.md),
    lg: imgPrefix(imgObj.lg),
  } as T;
};
