import { Badges } from './Badges/Badges';
import CategoryCard from './CategoryCard/CategoryCard';
import CategoryForm from './CategoryForm/CategoryForm';
import CategoryRating from './CategoryRating/CategoryRating';
import PriceBanner from './PriceBanner/PriceBanner';
import PriceRange from './PriceRange/PriceRange';
import ServicesCategories from './ServicesCategories/ServicesCategories';
import Faq from './Faq/Faq';
import MainServices from './MainServices/MainServices';
import SitemapLinks from './SitemapLinks/SitemapLinks';
import * as ProCardDetail from './ProCardDetail/ProCardDetail';
import CategoryFormAbTest from './CategoryForm/__abTest__/_CategoryFormAbTest';;

export {
  Badges,
  CategoryCard,
  CategoryRating,
  ServicesCategories,
  PriceBanner,
  PriceRange,
  MainServices,
  Faq,
  CategoryForm,
  SitemapLinks,
  ProCardDetail,
  CategoryFormAbTest,
};
