export const diacriticsTranslation = {
  á: 'a',
  à: 'a',
  â: 'a',
  ã: 'a',
  é: 'e',
  è: 'e',
  ê: 'e',
  ẽ: 'e',
  í: 'i',
  ì: 'i',
  î: 'i',
  ĩ: 'i',
  ó: 'o',
  ò: 'o',
  ô: 'o',
  õ: 'o',
  ú: 'u',
  ù: 'u',
  û: 'u',
  ũ: 'u',
  ç: 'c',
};
