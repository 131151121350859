import Image from 'next/image';
import classname from 'classnames';

import { Paragraph, Stars, TextLink } from 'darrius';

import { Dialog } from '../../atoms';

import { Certify } from './Certify/Certify';
import { imgPrefix } from '../../../utils';

import styles from './Badges.module.scss';

interface IBadgesProps {
  showYoutubeLink?: boolean;
}

export const Badges = ({ showYoutubeLink = false }: IBadgesProps) => {
  const badgesClass = classname({
    badges__default: !showYoutubeLink,
    badges__custom: showYoutubeLink,
  });

  return (
    <div className={`${styles.badges} badges`}>
      <Certify />

      <div
        className={`${styles['badges-class']} ${styles.badges__default} ${badgesClass}`}
      >
        <Stars rating={1} />

        <Paragraph size="4" text="Profissionais avaliados" />
      </div>

      {showYoutubeLink && (
        <div className={`${styles.badges__youtube} badges__youtube`}>
          <Image
            src={imgPrefix('/images/category/youtube.svg')}
            alt="Logo do Youtube"
            title="Ao clicar aqui irá abrir um modal com um vídeo no youtube te contanto um pouco mais sobre o que é o GetNinjas."
            width={24}
            height={24}
          />
          <Dialog.Trigger asChild>
            <TextLink text="Como funciona o GetNinjas?" url="#" />
          </Dialog.Trigger>
        </div>
      )}
    </div>
  );
};

export default Badges;
