import { Banner, Paragraph } from 'darrius';

import styles from './Faq.module.scss';

interface IFaqItem {
  id: number;
  question: string;
  answer: string;
}

interface IFaqProps {
  title: string;
  subtitle?: string;
  items: IFaqItem[];
  structuredData?: string;
}

const Faq = ({
  title,
  subtitle = null,
  items,
  structuredData = null,
}: IFaqProps) => (
  <Banner className={`${styles.faq} faq`}>
    <Paragraph
      title={title}
      text={subtitle}
      size="2"
      headline
      className={`${styles.faq__title} faq__title`}
    />

    {items.map((faqItem) => (
      <Paragraph
        key={faqItem.id}
        size="3"
        title={faqItem.question}
        text={faqItem.answer}
        className={`${styles.faq__item} faq__item`}
      />
    ))}

    {structuredData && (
      <script
        id="faq-configs"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: structuredData,
        }}
      />
    )}
  </Banner>
);

export default Faq;
