import { OTPInput, type SlotProps } from 'input-otp';

import classNames from 'classnames';

import styles from './Otp.module.scss';

export type TOtpPinStatus = 'success' | 'error' | 'default';

interface ISlotProps extends SlotProps {
  isPinSending: boolean;
  pinStatus: TOtpPinStatus;
}

interface IOtpProps {
  isPinSending: boolean;
  handleChangePin: (pinValue: string) => void;
  pinStatus: TOtpPinStatus;
}

// For default OTP length is 4 in GetNinjas context, in this moment we don't need to customize it.
const MAX_LENGTH = 4;

function FakeCaret() {
  return (
    <div className={styles.opt_fake_caret__content}>
      <div className={styles.opt_fake_caret__dash} />
    </div>
  );
}

function Slot(slotProps: ISlotProps) {
  const slotClassName = classNames(styles.otp_slot, {
    [styles.otp_slot__active]: slotProps.isActive,
    [styles.otp_slot__disabled]: slotProps.isPinSending,
    [styles.otp_slot__success]: slotProps.pinStatus === 'success',
    [styles.otp_slot__error]: slotProps.pinStatus === 'error',
  });

  return (
    <div className={slotClassName} data-testid="otp-slot">
      {slotProps.char !== null && (
        <div className={styles.otp_slot__char}>{slotProps.char}</div>
      )}
      {slotProps.hasFakeCaret && <FakeCaret />}
    </div>
  );
}

export default function Otp({
  handleChangePin,
  isPinSending,
  pinStatus,
}: IOtpProps) {
  return (
    <OTPInput
      onChange={handleChangePin}
      maxLength={MAX_LENGTH}
      containerClassName={styles.otp_container}
      disabled={isPinSending}
      render={({ slots }) => (
        <div className={styles.otp_content}>
          {slots.slice(0, 4).map((slot, idx) => (
            <Slot
              key={idx}
              pinStatus={pinStatus}
              isPinSending={isPinSending}
              {...slot}
            />
          ))}
        </div>
      )}
    />
  );
}
