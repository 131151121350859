import Image from 'next/image';

import { imgPrefix } from '../../../../utils';

import styles from './Certify.module.scss';

export const Certify = () => (
  <div className={styles.certify}>
    <Image
      src={imgPrefix('/images/shared/safe.svg')}
      alt="Selo de segurança"
      width={22}
      height={22}
    />

    <span className="text">Até 4 orçamentos grátis e seguros</span>
  </div>
);

export default Certify;
