import { Card, CardHeader, CardFooter, Paragraph, Stars } from 'darrius';

interface IReviewProps {
  content: string;
  pro: string;
  client: string;
  category: string;
  rating: number;
}

const Review = ({ content, pro, client, category, rating }: IReviewProps) => {
  const title = `${client} avaliou:`;
  const proField = `Para ${pro}/${category}`;

  return (
    <Card>
      <CardHeader>
        <Paragraph size="4" className="quote-m" title={title} text={content} />
      </CardHeader>
      <CardFooter>
        <Stars rating={rating} />
        <Paragraph size="4" text={proField} />
      </CardFooter>
    </Card>
  );
};

export default Review;
