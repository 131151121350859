import Image from 'next/image';

import { imgPrefix } from '../../../utils';

import styles from './ConfirmationBadge.module.scss';

export interface IConfirmationBadgeProps {
  text: string;
  alt: string;
  size: number;
}

const ConfirmationBadge = ({ text, alt, size }: IConfirmationBadgeProps) => (
  <p className={styles['confirmation-badge']}>
    <Image
      src={imgPrefix('/images/category/finished-request.svg')}
      alt={alt}
      width={size}
      height={size}
    />

    <span className={styles['confirmation-badge__label']}>{text}</span>
  </p>
);

export default ConfirmationBadge;
