import { useState, useCallback, useRef } from 'react';

import StepByStepForm from './StepByStepForm/StepByStepForm';
import JsonForm from './JsonForm/JsonForm';

import { type IFormData } from '../../../modules/shared/pageProps/formData/@interfaces';
import { type ICategoryTreeRecord } from '../../../modules/budget/transformations/form/@interfaces';

import styles from './CategoryForm.module.scss';
interface ICategoryFormProps extends IFormData {
  className?: string;
  rootCategoryName?: string;
}

const CategoryForm = ({
  categoryTree,
  formContent,
  className,
}: ICategoryFormProps) => {
  const formRef = useRef(null);

  const [category, setCategory] = useState(() => {
    if (categoryTree.children) {
      return null;
    }

    return categoryTree;
  });

  const handleOnSelectedItem = useCallback((item: ICategoryTreeRecord) => {
    setCategory(item);
  }, []);

  return (
    <div
      id="form"
      className={`category-form ${styles['category-form']} ${className}`}
      tabIndex={-1}
      ref={formRef}
    >
      <div className={styles['category-form__content']}>
        {!category ? (
          <StepByStepForm
            ref={formRef}
            categoryTree={categoryTree}
            onSelectedItem={handleOnSelectedItem}
          />
        ) : (
          <JsonForm category={category} formContent={formContent} />
        )}
      </div>
      <div
        className={`${styles['category-form__deadline-info']} category-form__deadline-info`}
      >
        Orçamentos em até 60 minutos
      </div>
    </div>
  );
};

export default CategoryForm;
