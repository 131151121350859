import { List, ListItem, TextLink } from 'darrius';
import Link from 'next/link';
import classnames from 'classnames';

import { type ICategorySitemapRecord } from '../../../modules/sitemap/@interfaces';

import styles from './SitemapLinks.module.scss';

interface ISitemapLinksProps {
  items: ICategorySitemapRecord[];
  isRoot?: boolean;
  showAll?: {
    url: string;
    text: string;
  };
}

const SitemapLinks = ({
  items,
  isRoot = true,
  showAll = null,
}: ISitemapLinksProps) => {
  if (!items) return null;

  const listClassName = classnames(styles.items, {
    'sitemap-links': isRoot,
    'row space-between': isRoot,
    [styles['items--root']]: isRoot,
  });

  const itemClassName = classnames({
    'col col-sm-12 col-md-3': isRoot,
  });

  const linkClassName = classnames('text-link', styles.link, {
    [styles['link--root']]: isRoot,
  });

  return (
    <List className={listClassName}>
      {items.map((item) => (
        <ListItem key={item.id} className={itemClassName}>
          <Link href={item.url} className={linkClassName}>
            {item.name}
          </Link>

          <SitemapLinks items={item.children} isRoot={false} />

          {showAll && (
            <List className={styles.items}>
              <ListItem>
                <TextLink
                  url={`${showAll.url}/${item.slug}`}
                  text={showAll.text}
                />
              </ListItem>
            </List>
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default SitemapLinks;
