import {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
  useTransition,
  type ReactNode,
  type ChangeEvent,
  type Dispatch,
  type SetStateAction,
} from 'react';

import { triggerEvent, diacriticsTranslation } from '../../../../../utils';

import { type ICategoryTreeRecord } from '../../../../../modules/budget/transformations/form/@interfaces';

type TCategoryStepByStep = {
  id: number;
  name: string;
  slug: string;
  children?: TCategoryStepByStep[];
};

type TSubcategoryStepByStep = {
  rootName: string;
  childrenName?: string;
};

type TFilterCategoriesState = 'EMPTY_SEARCH' | 'SEARCHED' | 'NOT_FOUND';

export type TCategoryStepByStepForm = {
  id: number;
  name: string;
  slug: string;
  categoriesToSearch?: string;
  children?: TCategoryStepByStep[];
  subcategory?: TSubcategoryStepByStep;
};

export type TFilterCategories = {
  state: TFilterCategoriesState;
  data: TCategoryStepByStepForm[];
};

export interface IStepByStepContext {
  searchBarValue: string;
  onSearchCategoryTransitionIsPending: boolean;
  currentStep: number;
  categoryIsNotFound: boolean;
  categoriesSearched: ICategoryTreeRecord[];
  steps: ICategoryTreeRecord[][];
  onCleanSearchCategory: () => void;
  onSetSteps: Dispatch<SetStateAction<ICategoryTreeRecord[][]>>;
  handleSearchCategory: (
    event: ChangeEvent<HTMLInputElement>,
    slug: string
  ) => void;
  handleCleanSearchBar: (
    event: React.MouseEvent<HTMLElement>,
    slug: string
  ) => void;
}

interface IStepByStepProviderProps {
  children: ReactNode;
  formSteps: ICategoryTreeRecord[][];
}

export const StepByStepContext = createContext({} as IStepByStepContext);

export const StepByStepProvider = ({
  children,
  formSteps,
}: IStepByStepProviderProps) => {
  const [searchBarValue, setSearchBarValue] = useState('');
  const [steps, setSteps] = useState(formSteps);
  const [categoryIsNotFound, setCategoryIsNotFound] = useState(false);
  const [categoriesSearched, setCategoriesSearched] = useState<
    ICategoryTreeRecord[]
  >([]);

  const [onSearchCategoryTransitionIsPending, startOnSearchCategoryTransition] =
    useTransition();

  const currentStep = steps.length;

  const subcategoriesChildrenNames = useMemo(() => {
    return steps[steps.length - 1].map((subcategory) => {
      if (subcategory.children) {
        return subcategory.children.map((subcategoryChildren) => {
          return {
            id: subcategoryChildren.id,
            name: subcategoryChildren.name,
            slug: subcategoryChildren.slug,
            categoriesToSearch: subcategoryChildren.name.concat(
              ' ',
              subcategory.name
            ),
            children: subcategoryChildren.children,
            subcategory: {
              rootName: subcategory.name,
              childrenName: subcategoryChildren.name,
            },
          } as TCategoryStepByStepForm;
        });
      } else {
        return [
          {
            id: subcategory.id,
            name: subcategory.name,
            slug: subcategory.slug,
            categoriesToSearch: subcategory.name,
            subcategory: {
              rootName: subcategory.name,
            },
          } as TCategoryStepByStepForm,
        ];
      }
    });
  }, [steps]);

  const filterCategories = useCallback(
    (categoryToSearch: string) => {
      const diacriticsTranslationRegex = new RegExp(
        `[${Object.keys(diacriticsTranslation).join('')}]`,
        'g'
      );

      const categoryToSearchWithoutDiacritics = categoryToSearch.replace(
        diacriticsTranslationRegex,
        (char) => {
          return diacriticsTranslation[char] || char;
        }
      );

      const categoryToSearchIsEmpty = categoryToSearchWithoutDiacritics === '';

      if (categoryToSearchIsEmpty) {
        return {
          state: 'EMPTY_SEARCH' as TFilterCategoriesState,
          data: [],
        };
      }

      const selectedCategories: TCategoryStepByStepForm[] = [];

      subcategoriesChildrenNames.forEach((childrenNames) => {
        childrenNames.forEach((child) => {
          const categoryNameParse = String(child.categoriesToSearch)
            .trim()
            .toLowerCase();

          const categoryNameParsedWithoutDiacritics = categoryNameParse.replace(
            diacriticsTranslationRegex,
            (char) => {
              return diacriticsTranslation[char] || char;
            }
          );

          if (
            categoryNameParsedWithoutDiacritics.includes(
              categoryToSearchWithoutDiacritics
            )
          ) {
            const childrenName = child.subcategory.childrenName
              ? child.subcategory.childrenName
              : '';

            const categoriesToSearch = child.subcategory.rootName.concat(
              ' ',
              childrenName
            );

            const categoryData = {
              id: child.id,
              children: child.children,
              name: child.name,
              slug: child.slug,
              categoriesToSearch,
              subcategory: {
                rootName: child.subcategory.rootName,
                childrenName,
              },
            } as TCategoryStepByStepForm;

            selectedCategories.push(categoryData);
          }
        });
      });

      if (selectedCategories.length === 0) {
        return {
          state: 'NOT_FOUND' as TFilterCategoriesState,
          data: [],
        };
      }

      return {
        state: 'SEARCHED' as TFilterCategoriesState,
        data: selectedCategories,
      };
    },
    [subcategoriesChildrenNames]
  );

  const onSearchCategory = useCallback(
    (categorySearched: string, slug: string) => {
      const categoryToSearch = String(categorySearched).trim().toLowerCase();

      const { data, state } = filterCategories(categoryToSearch);

      if (state === 'NOT_FOUND') {
        setCategoryIsNotFound(true);

        return;
      }

      if (state === 'EMPTY_SEARCH') {
        setCategoriesSearched([]);
        setCategoryIsNotFound(false);

        triggerEvent('step_by_step_form-empty_search', {
          value: categorySearched,
          slug,
        });

        return;
      }

      setCategoriesSearched(data);
      setCategoryIsNotFound(false);

      triggerEvent('step_by_step_form-is_input-value', {
        value: categorySearched,
        slug,
      });
    },
    [filterCategories]
  );

  function onSetSteps(newSteps: ICategoryTreeRecord[][]) {
    setSteps(newSteps);
  }

  function onCleanSearchCategory() {
    setSearchBarValue('');
    setCategoriesSearched([]);
    setCategoryIsNotFound(false);
  }

  function handleCleanSearchBar(
    event: React.MouseEvent<HTMLElement>,
    slug: string
  ) {
    event.preventDefault();

    triggerEvent('step_by_step_form-clean-search', {
      slug,
    });

    onCleanSearchCategory();
  }

  function handleSearchCategory(
    event: ChangeEvent<HTMLInputElement>,
    slug: string
  ) {
    const text = event.target.value;

    setSearchBarValue(event.target.value);

    const isValidText = /^[a-zA-Z0-9\sÀ-ÿçÇ ]*$/.test(text);

    if (!isValidText) {
      setCategoryIsNotFound(true);

      return;
    }

    startOnSearchCategoryTransition(() => {
      onSearchCategory(text.trim(), slug);
    });
  }

  return (
    <StepByStepContext.Provider
      value={{
        searchBarValue,
        onSearchCategoryTransitionIsPending,
        steps,
        currentStep,
        categoriesSearched,
        categoryIsNotFound,
        onCleanSearchCategory,
        onSetSteps,
        handleSearchCategory,
        handleCleanSearchBar,
      }}
    >
      {children}
    </StepByStepContext.Provider>
  );
};

export function useStepByStep() {
  return useContext(StepByStepContext);
}
