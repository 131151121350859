import { useEffect, useState } from 'react';

import { axiosInstance } from '../utils/axiosInstance';

import { navigate } from '../utils';

import { type TAuthUser } from '../pages/api/auth/login';
import { type IUserApiResponse } from '../pages/api/users';
import { type IConversationUnreadResponse } from '../pages/api/users/conversation/unread';
import { type IAuthLogoutResponse } from '../pages/api/auth/logout';

interface IUseClientParams {
  shouldFetchUserData?: boolean;
}

export function useClientUser({
  shouldFetchUserData = true,
}: IUseClientParams = {}) {
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [userData, setUserData] = useState<TAuthUser>(null);
  const [userNotificationsCount, setUserNotificationsCount] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    async function getUserData() {
      try {
        const requestOptions = {
          url: `${process.env.NEXT_PUBLIC_API_CLIENTS}/users`,
          method: 'GET',
        } as const;

        const { data: dataUserResponse } =
          await axiosInstance<IUserApiResponse>(requestOptions);

        setUserData(dataUserResponse.data);
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
    }

    async function getNotifications() {
      try {
        const requestOptions = {
          url: `${process.env.NEXT_PUBLIC_API_CLIENTS}/users/conversation/unread`,
          method: 'GET',
        } as const;

        const { data: dataMessage } =
          await axiosInstance<IConversationUnreadResponse>(requestOptions);

        setUserNotificationsCount(dataMessage.notificationCount);
      } catch (error) {
        setUserNotificationsCount(0);
      }
    }

    async function getAllData() {
      await Promise.all([getUserData(), getNotifications()]);
    }

    if (shouldFetchUserData) {
      getAllData();
    }

    setIsLoadingUser(false);
  }, [shouldFetchUserData]);

  async function handleUserSignOut() {
    try {
      const requestOptions = {
        url: `${process.env.NEXT_PUBLIC_API_CLIENTS}/auth/logout`,
        method: 'POST',
      } as const;

      const { data } = await axiosInstance<IAuthLogoutResponse>(requestOptions);

      if (data.success) {
        return navigate('/');
      }

      return navigate('/404');
    } catch (error) {
      return navigate('/404');
    }
  }

  return {
    user: userData,
    userNotificationsCount,
    isAuthenticated,
    isLoadingUser,
    handleUserSignOut,
  };
}
