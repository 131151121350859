import { forwardRef } from 'react';
import { IoMdClose } from 'react-icons/io';

import * as Dialog from '@radix-ui/react-dialog';

import { DialogInternalLoading } from './DialogInternalLoading';

import { type IDialogContentProps } from './@interfaces';

import styles from './Dialog.module.scss';
import { triggerEvent } from '../../../utils';

const Root = Dialog.Root;

const Title = Dialog.Title;

const Description = Dialog.Description;

Root.displayName = 'DialogRoot';

const Portal = Dialog.Portal;

Portal.displayName = 'DialogPortal';

const Trigger = Dialog.Trigger;

Trigger.displayName = 'DialogTrigger';

const Content = forwardRef<HTMLDivElement, IDialogContentProps>(
  (
    {
      title = null,
      description = null,
      loading = null,
      children,
      className = '',
      hideCloseButton = false,
      closeButtonColor = '',
    },
    forwardedRef
  ) => {
    function contentToRender() {
      const isContentLoading = loading && loading.enabled;

      if (isContentLoading) {
        return <DialogInternalLoading title={loading.title} />;
      }

      return <>{children}</>;
    }

    return (
      <div className={`dialog ${styles.dialog}`}>
        <Dialog.Overlay className={styles.dialog__overlay} />

        <Dialog.Content
          ref={forwardedRef}
          className={`${styles.dialog__content} ${className}`}
        >
          {title && (
            <Dialog.Title
              className={styles.dialog__content_title}
              hidden={title.hidden}
            >
              {title.text}
            </Dialog.Title>
          )}

          {description && (
            <Dialog.Description hidden={description.hidden}>
              {description.text}
            </Dialog.Description>
          )}

          {!hideCloseButton && (
            <Dialog.Close asChild>
              <button
                type="button"
                className={`${styles.dialog__content_close} dialog__content_close`}
                onClick={() => triggerEvent('close-modal')}
              >
                <IoMdClose size={24} color={closeButtonColor} />
              </button>
            </Dialog.Close>
          )}

          {contentToRender()}
        </Dialog.Content>
      </div>
    );
  }
);

Content.displayName = 'DialogContent';

export { Root, Content, Portal, Trigger, Title, Description };
