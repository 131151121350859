import { type RefObject, useCallback } from 'react';

export function useScrollRef(ref: unknown) {
  const scrollToTarget = useCallback(
    (behavior: ScrollBehavior = 'smooth') => {
      const refObject = ref as RefObject<HTMLElement | HTMLFormElement>;

      if (refObject.current) {
        refObject.current.scrollIntoView({
          behavior,
        });
      }
    },
    [ref]
  );

  return {
    scrollToTarget,
  };
}
