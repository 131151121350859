import { Banner, Button, Row, Col } from 'darrius';

import PriceRange from '../PriceRange/PriceRange';

import styles from './PriceBanner.module.scss';
import Link from 'next/link';

interface IPriceBannerProps {
  categorySlug: string;
  name: string;
  minimum: string;
  maximum: string;
  medium: string;
  slug?: string;
}

const PriceBanner = ({
  categorySlug,
  name,
  minimum,
  maximum,
  medium,
  slug = '',
}: IPriceBannerProps) => (
  <div role="banner" className={`price_banner ${styles.price_banner} `}>
    <Row>
      <Col className="col-sm-12 col-md-8 col-md-offset-2">
        <Banner>
          <PriceRange
            categorySlug={categorySlug}
            name={name}
            slug={slug}
            minimum={minimum}
            maximum={maximum}
            medium={medium}
          />
        </Banner>
      </Col>
    </Row>

    <div className={`budget_btn ${styles.budget_btn} `}>
      <Row>
        <Col className="col-sm-12 col-md-8 col-md-offset-2">
          <Link href={`/${categorySlug}`} passHref legacyBehavior>
            <Button.Link className="primary" fullWidth>
              Solicitar Orçamento
            </Button.Link>
          </Link>
        </Col>
      </Row>
    </div>
  </div>
);

export default PriceBanner;
