import { Dialog } from '../../atoms';

import styles from './DialogLoading.module.scss';

function CustomLoading() {
  return <div className={styles['dialog-loading__custom-loading']} />;
}

export default function DialogLoading() {
  return (
    <Dialog.Root open={true}>
      <Dialog.Portal>
        <Dialog.Content
          className={styles['dialog-loading__content']}
          hideCloseButton
        >
          <CustomLoading />
          <p>Por favor, aguarde...</p>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
