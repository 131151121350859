import { useCallback, useState } from 'react';

import Image from 'next/image';

import { MdClose, MdSearch } from 'react-icons/md';

import classNames from 'classnames';

import { Loading } from 'darrius';

import { imgPrefix, triggerEvent } from '../../../../../../utils';

import {
  useStepByStep,
  type TCategoryStepByStepForm,
} from '../../context/StepByStepContext';

import { Steps } from './Steps/Steps';

import styles from './StepByStep.module.scss';

import { type ICategoryTreeRecord } from '../../../../../../modules/budget/transformations/form/@interfaces';

interface IStepByStepProps {
  categoryTree: ICategoryTreeRecord;
  onSelectedItem: (category: ICategoryTreeRecord) => void;
  scrollToTarget: () => void;
}

const getInitialTitle = (categoryTree: ICategoryTreeRecord) =>
  `Qual serviço de ${categoryTree.name} está precisando?`;

export function StepByStep({
  categoryTree,
  onSelectedItem,
  scrollToTarget,
}: IStepByStepProps) {
  const [title, setTitle] = useState(() => getInitialTitle(categoryTree));

  const {
    searchBarValue,
    currentStep,
    onSearchCategoryTransitionIsPending,
    onSetSteps,
    onCleanSearchCategory,
    handleSearchCategory,
    handleCleanSearchBar,
  } = useStepByStep();

  const slug = categoryTree.slug;

  const searchCleanClassNames = classNames(styles.search__clean, {
    [styles.search__clean_isActived]: !!searchBarValue,
  });

  function triggerSearchBar() {
    triggerEvent('step_by_step_form-search_bar', {
      slug,
    });
  }

  const handleBackStep = useCallback(() => {
    onCleanSearchCategory();

    const initialTitle = getInitialTitle(categoryTree);

    setTitle(initialTitle);

    triggerEvent('step_by_step_form-back_step', {
      title,
      currentStep,
    });

    onSetSteps((oldStep) => {
      return oldStep.filter((_, index) => index < oldStep.length - 1);
    });
  }, [categoryTree, title, currentStep, onCleanSearchCategory, onSetSteps]);

  const handleNextStep = useCallback(
    (category: TCategoryStepByStepForm) => {
      onCleanSearchCategory();

      triggerEvent('step_by_step_form-next_step', {
        title,
        currentStep,
        categoryName: category.name,
        slug: category.slug,
      });

      if (category.children) {
        setTitle(category.name);

        onSetSteps((oldStep) => [...oldStep, category.children]);
      } else {
        onSelectedItem(category);
      }

      scrollToTarget();
    },
    [
      title,
      currentStep,
      onSetSteps,
      scrollToTarget,
      onSelectedItem,
      onCleanSearchCategory,
    ]
  );

  return (
    <div className={`${styles['step-by-step-form']} step-by-step-form`}>
      <div
        className={`${styles['step-by-step-form__header']} step-by-step-form__header`}
      >
        {currentStep > 1 && (
          <button
            data-testid="step-by-step-form__back-button"
            className={`${styles['step-by-step-form__back-button']} step-by-step-form__back-button`}
            onClick={handleBackStep}
          >
            <Image
              src={imgPrefix('/images/shared/arrow-back.svg')}
              alt="Seta para passo anterior"
              width={16}
              height={16}
            />
          </button>
        )}

        <h2
          className={`${styles['step-by-step-form__title']} step-by-step-form__title`}
        >
          {title}
        </h2>
      </div>

      <form className={`${styles.search} search`}>
        {/* eslint-disable jsx-a11y/label-has-associated-control */}
        <label className={styles.search__label}>
          <MdSearch />
          <input
            type="text"
            placeholder="O que você precisa?"
            data-testid="search__input"
            onClick={triggerSearchBar}
            value={searchBarValue}
            onChange={(event) => handleSearchCategory(event, slug)}
          />
        </label>
        <button
          onClick={(event) => handleCleanSearchBar(event, slug)}
          className={`${searchCleanClassNames} search__clean`}
          disabled={
            searchBarValue === '' || onSearchCategoryTransitionIsPending
          }
          data-testid="search__clean-button"
        >
          {onSearchCategoryTransitionIsPending ? <Loading /> : <MdClose />}
        </button>
      </form>

      <Steps slug={slug} handleNextStep={handleNextStep} />
    </div>
  );
}
