import classnames from 'classnames';
import Link from 'next/link';

import { Card, Row, Col, Button, Icon, Paragraph, ProDetails } from 'darrius';

import { type ICategoryCard } from '../../../locales/@interfaces/home/categoryCard';

import styles from './CategoryCard.module.scss';

export interface ICategoryCardProps {
  data: ICategoryCard;
}

const CategoryCard = ({ data }: ICategoryCardProps) => {
  const { inverted, title, description, icon, link, linkText, proDetails } =
    data;

  const imgColClassNames = classnames(
    'img-container col-sm-12 col-md-5 col-fill',
    {
      'last-md': inverted,
    }
  );

  return (
    <Card
      className={`category-card space-top space-bottom ${styles['category-card']}`}
    >
      <Row className="middle-sm start-sm">
        <Col className={imgColClassNames}>
          <ProDetails
            img={proDetails.img}
            name={proDetails.name}
            description={proDetails.description}
            rating={proDetails.rating}
            tagPosition={inverted ? 'left' : 'right'}
          />
        </Col>

        <Col className="content col-sm-12 col-md-7">
          <Icon src={icon} alt={`Ícone de ${title}`} size={48} />

          <Paragraph title={title} text={description} size="2" />

          <Row>
            <Col className="col-sm-12 col-lg-9">
              <Link href={link} passHref legacyBehavior>
                <Button.Link className="primary" fullWidth>
                  {linkText}
                </Button.Link>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default CategoryCard;
