import React, { useCallback, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import clsx from 'clsx';

import { Paragraph, ProgressBar } from 'darrius';

import { Loading } from '../../../atoms';

import { notifyErrorClient } from '../../../../services/notifyError/client';

import { triggerEvent, getPhone, navigate } from '../../../../utils';

import { dynamicSubmitMessage } from '../JsonForm/dynamicSubmitMessage';

import { AUTH_SESSION_FEATURE_IS_ENABLED } from '../../../../utils/featureFlags/authSession';

import {
  type IChangeSubmitPayloadParams,
  type IFormData,
  type IHandleOnStepChangeParams,
  type IHandleSubmitErrorParams,
  type IHandleSubmitSuccessParams,
  type IJasonComponentProps,
  type IJsonFormProps,
  type IRedirectSuccessParams,
  type IShowErrorParams,
  type TStep,
} from '../JsonForm/@interfaces';

import styles from './_JsonFormAbTest.module.scss';
import {
  type IImageUploadDTO,
  ImageUpload,
} from './_ImageUploadAbTest/ImageUpload';

const JasonComponent = dynamic<IJasonComponentProps>(
  () =>
    import('react-json-form') as unknown as Promise<
      React.ComponentType<IJasonComponentProps>
    >,
  {
    ssr: false,
  }
);

const ZIPCODE_EXTERNAL_LINK = 'http://www.buscacep.correios.com.br';

const JsonFormAbTest = ({
  category,
  formContent = null,
}: Partial<IJsonFormProps>) => {
  const [formIsLoading, setFormIsLoading] = useState(formContent === null);
  const [submitFormIsLoading, setSubmitFormIsLoading] = useState(
    formContent === null
  );
  const [messageIndex, setMessageIndex] = useState(0);
  const [progress, setProgress] = useState(1);
  const [formData, setFormData] = useState({ form: formContent } as IFormData);
  const [error, setError] = useState(false);
  const [image, setImage] = useState<IImageUploadDTO>(null);

  const formLength = formData.form && formData.form.steps.length;

  const formDataEndpoint = `${process.env.NEXT_PUBLIC_API_CLIENTS}/jsonforms/${category.id}`;
  const formSubmitEndpoint = `${process.env.NEXT_PUBLIC_API_CLIENTS}/requests/${category.id}`;

  const additionalInfoStep = progress === formLength - 4;

  const handleShowImageUpload = clsx({
    [styles['show-upload']]: additionalInfoStep,
    [styles['hide-upload']]: !additionalInfoStep,
  });

  const handleSaveImage = (img) => {
    setImage(img);
  };

  useEffect(() => {
    async function getFormData() {
      if (formContent) {
        return;
      }

      try {
        const data = await fetch(formDataEndpoint);
        const body = await data.json();

        setFormData({ form: body });

        setFormIsLoading(false);
        setSubmitFormIsLoading(false);
      } catch (err) {
        notifyErrorClient(err);
        setError(true);
      }
    }

    getFormData();
  }, [formDataEndpoint, formContent]);

  const handleOnReady = useCallback(() => {
    if (formData.form.databaseId) {
      triggerEvent('json_form-init', {
        categoryID: formData.form.databaseId,
      });

      document.cookie = `category-info=${JSON.stringify(category)}`;
    }
  }, [formData, category]);

  const handleOnStepChange = useCallback(
    ({ direction, activeStepIndex }: IHandleOnStepChangeParams) => {
      if (direction === 'next') {
        triggerEvent('json_form-next_step', {
          currentStep: activeStepIndex,
        });

        if (progress === formLength - 1) {
          triggerEvent('json_form-before_last_step');
        }

        if (progress === formLength - 5) {
          triggerEvent('json_form-additional_info', {
            formId: category.id,
          });
        }

        setProgress((oldProgress) => oldProgress + 1);
      } else {
        triggerEvent('json_form-back_step', {
          currentStep: activeStepIndex,
        });

        setProgress((oldProgress) => oldProgress - 1);
      }
    },
    [progress, formLength, category.id]
  );

  const handleOnSubmitFieldError = useCallback((step: TStep) => {
    if (step === 'last') {
      triggerEvent('json_form-field_error_in_last_step');

      return;
    }

    triggerEvent('json_form-field_error');
  }, []);

  const handleZipcodeExternalLinkClick = useCallback((event: Event) => {
    event.stopPropagation();

    window.open(ZIPCODE_EXTERNAL_LINK, '_blank');

    triggerEvent('json_form-zip_link_click');

    return false;
  }, []);

  const authRedirectSuccess = (params: IRedirectSuccessParams) => {
    const phoneId = params.phone_id;
    const userId = params._embedded.user.id;
    const requestId = params.uuid;

    window.location.assign(
      `/requests/${requestId}/auth/user/${userId}/phone/${phoneId}`
    );
  };

  const redirectSuccess = (params: IRedirectSuccessParams) => {
    if (AUTH_SESSION_FEATURE_IS_ENABLED) {
      authRedirectSuccess(params);

      return;
    }

    const { _links: links } = params;

    if (links.phone_verification) {
      navigate(links.phone_verification.href);

      return;
    }

    navigate(links.request.href);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMessageIndex(
        (prevIndex) => (prevIndex + 1) % dynamicSubmitMessage.length
      );
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  const handleSubmit = () => {
    if (progress === formLength) {
      setFormIsLoading(false);
      setSubmitFormIsLoading(true);
    } else {
      setFormIsLoading(true);
      setSubmitFormIsLoading(false);
    }
  };

  const handleSubmitSuccess = ({
    request,
    config,
  }: IHandleSubmitSuccessParams) => {
    const response = JSON.parse(request.response);
    const { data } = JSON.parse(config.data);

    const phone = getPhone(data);
    const requestId = response._links.request.href.split('/requests/')[1];

    triggerEvent('json_form-submit_success', {
      phone,
      request: requestId,
      formId: category.id,
    });

    redirectSuccess(response);
  };

  const showError = (data: IShowErrorParams) => {
    try {
      const links = JSON.parse(data.error)._links;

      if (links.request_already_created) {
        navigate(links.request_already_created.href);
      }
    } catch (e) {
      setError(true);
    }
  };

  const handleSubmitError = ({ response }: IHandleSubmitErrorParams) => {
    triggerEvent('json_form-submit_error', {
      error: response.data.error,
    });

    showError(response.data);
  };

  const handleZipcodeFetchSuccess = useCallback(() => {
    triggerEvent('json_form-zip_success');
  }, []);

  const handleOnZipcodeFetchError = useCallback(() => {
    triggerEvent('json_form-zip_error');
  }, []);

  const handleEmailFetchSuccess = useCallback(() => {
    triggerEvent('json_form-email_success');
  }, []);

  const handleEmailFetchError = useCallback(() => {
    triggerEvent('json_form-email_error');
  }, []);

  const changeSubmitPayload = useCallback(
    (body: IChangeSubmitPayloadParams) => {
      const firstStep = body.data[0]
        .filter((item) => item.length === 1)
        .reduce((acc, next) => acc.concat(next), []);

      const data = {
        0: firstStep,
        1: body.data[1],
        address: body.data.address,
      };

      if (!!image && image.images_ids.length > 0) {
        Object.assign(data, { images_ids: image.images_ids });
      }

      return { data };
    },
    [image]
  );

  if (error) {
    return (
      <div className={styles.form__error}>
        <p>Um erro inesperado ocorreu.</p>
        <p>Recarregue a página e tente novamente.</p>
      </div>
    );
  }

  if (formIsLoading) {
    return (
      <div className={styles.form__loading}>
        <Loading />
      </div>
    );
  }

  if (submitFormIsLoading) {
    return (
      <div className={styles.form__loading}>
        <Loading />
        <Paragraph
          size="4"
          className="quote-m"
          title="Buscando Profissionais..."
          text={dynamicSubmitMessage[messageIndex]}
        />
      </div>
    );
  }

  return (
    <>
      <ProgressBar max={formLength} value={progress} />

      <JasonComponent
        action={formSubmitEndpoint}
        data={formData}
        buttonCustomClasses="button primary default full-width"
        backButtonCustomClasses="button secondary default full-width"
        onReady={handleOnReady}
        onStepChange={handleOnStepChange}
        onSubmitFieldError={handleOnSubmitFieldError}
        handleZipcodeExternalLinkClick={handleZipcodeExternalLinkClick}
        onZipcodeFetchSuccess={handleZipcodeFetchSuccess}
        onZipcodeFetchError={handleOnZipcodeFetchError}
        onEmailFetchSuccess={handleEmailFetchSuccess}
        onEmailFetchError={handleEmailFetchError}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        onSubmitError={handleSubmitError}
        changeSubmitPayload={changeSubmitPayload}
      />

      <ImageUpload
        className={handleShowImageUpload}
        onSaveImage={handleSaveImage}
      />
    </>
  );
};

export default JsonFormAbTest;
