import { type ReactNode } from 'react';
import classnames from 'classnames';

import styles from './FlatCard.module.scss';

export interface IFlatCardProps {
  /**
   * Card content
   */
  children: ReactNode;
  /**
   * Card classname
   */
  className?: string;
  /**
   * Is Card selectable
   */
  selectable?: boolean;
}

export const FlatCard = ({
  children,
  className,
  selectable,
}: IFlatCardProps) => {
  const classNames = classnames('flat-card', styles['flat-card'], className, {
    selectable,
  });

  return <div className={classNames}>{children}</div>;
};
