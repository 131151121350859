import { Stars, Row, Col } from 'darrius';

import { type TReview } from '../../../modules/shared/pageProps/ratings/reviews/@interfaces';

import styles from './CategoryRating.module.scss';

interface ICategoryRatingProps {
  general?: {
    rating: {
      value: number;
      max: number;
    };
    reviewCount: number;
    structuredData: string;
  };
  reviews: {
    title: string;
    items: TReview[];
  };
}

const CategoryRating = ({ general = null, reviews }: ICategoryRatingProps) => (
  <div className={styles['category-rating']}>
    {general && (
      <>
        <div className={styles['category-rating__general']}>
          <p className={styles['category-rating__general-rating']}>
            <b>{general.rating.value}</b> / {general.rating.max}
          </p>

          <Stars quantity={general.rating.max} rating={general.rating.value} />

          <p className={styles['category-rating__general-text']}>
            <b>{general.reviewCount}</b> clientes avaliaram nossos profissionais
          </p>
        </div>

        <script
          id="newrelic-configs"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: general.structuredData,
          }}
        />
      </>
    )}

    <h2 className={styles['category-rating__title']}>{reviews.title}</h2>

    <Row className="space-between slider-sm">
      {reviews.items.map((review) => (
        <Col key={review.id} className="col-sm-12">
          <div className={styles['category-rating__review']}>
            <p className={styles['category-rating__review-cli']}>
              <b>{review.cliName}</b> avaliou:
            </p>

            <p className={styles['category-rating__review-text']}>
              {review.text}
            </p>

            <Stars quantity={review.rating.max} rating={review.rating.value} />

            <p className={styles['category-rating__review-pro']}>
              para{' '}
              <b>
                {review.proName} / {review.profession}
              </b>
            </p>
          </div>
        </Col>
      ))}
    </Row>
  </div>
);

export default CategoryRating;
