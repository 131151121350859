import { Dialog } from '../../../../atoms';
import { Button, Col, Container, Row, Typography } from 'darrius';

export type TRemoveImageDialogProps = {
  handleRemoveImage: any;
};

export function RemoveImageDialog({
  handleRemoveImage,
}: TRemoveImageDialogProps) {
  return (
    <Dialog.Portal>
      <Dialog.Content>
        <Container>
          <Row>
            <Col className='col-sm-12 space-bottom'>
              <Typography.Subtitle size='m'>
                Tem certeza que deseja apagar a imagem?
              </Typography.Subtitle>
            </Col>
          </Row>

          <Row>
            <Col className='col-sm-6'>
              <Dialog.Trigger asChild>
                <Button.Root variation="secondary" fullWidth>
                  Cancelar
                </Button.Root>
              </Dialog.Trigger>
            </Col>

            <Col className='col-sm-6'>
              <Button.Root variation="primary" fullWidth onClick={handleRemoveImage}>
                Apagar
              </Button.Root>
            </Col>
          </Row>
        </Container>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
