import React, { useState } from 'react';

import ArrowButton from './ArrowButton/ArrowButton';

import styles from './Carousel.module.scss';

interface ICarouselProps {
  className?: string;
  children: React.ReactChild[];
}

const Carousel = ({ className = '', children }: ICarouselProps) => {
  const [current, setCurrent] = useState(0);

  const childrenCount = React.Children.count(children);

  const getPrevious = () => (current > 0 ? current - 1 : childrenCount - 1);

  const getNext = () => (current < childrenCount - 1 ? current + 1 : 0);

  return (
    <div
      className={`${styles.carousel} ${className}`}
      data-children-count={childrenCount}
    >
      <ArrowButton type="prev" onClick={() => setCurrent(getPrevious())} />

      <ArrowButton type="next" onClick={() => setCurrent(getNext())} />

      <div className={styles.carousel__items}>
        {React.Children.map(children, (item, i) => (
          <div
            key={i}
            className={`${styles.carousel__item} carousel__item`}
            data-is-current={i === current}
            data-is-previous={getPrevious() === i}
            data-is-next={getNext() === i}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
