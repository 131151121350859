interface IHTMLRenderProps {
  children: string;
  className?: string;
}

const HTMLRender = ({ children, className = '' }: IHTMLRenderProps) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: children }} />
);

export default HTMLRender;
