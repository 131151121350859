import Image from 'next/image';

import { imgPrefix } from '../../../../../../../utils';

import { type TCategoryStepByStepForm } from '../../../context/StepByStepContext';
import { type ICategoryTreeRecord } from '../../../../../../../modules/budget/transformations/form/@interfaces';

import styles from './StepItem.module.scss';

interface IStepItemWithoutIsNotFoundProps {
  category: TCategoryStepByStepForm;
  handleNextStep: (
    category: TCategoryStepByStepForm | ICategoryTreeRecord
  ) => void;
  isNotFound?: never;
}

interface IStepItemWithIsNotFoundProps {
  isNotFound: boolean;
  category?: never;
  handleNextStep?: never;
}

type TStepItemProps =
  | IStepItemWithoutIsNotFoundProps
  | IStepItemWithIsNotFoundProps;

export function StepItem({
  category,
  handleNextStep,
  isNotFound = false,
}: TStepItemProps) {
  if (isNotFound) {
    return (
      <li
        className={`${styles['step-by-step-form__item']} step-by-step-form__item`}
      >
        <label htmlFor="not-found">
          <p>Nenhum resultado encontrado.</p>
        </label>
      </li>
    );
  }

  return (
    <li
      className={`${styles['step-by-step-form__item']} step-by-step-form__item`}
    >
      <label>
        <input
          type="radio"
          data-testid="step-by-step-form__next-button"
          id={String(category.id)}
          value={category.name}
          onClick={() => handleNextStep(category)}
        />

        {category.subcategory && category.subcategory.childrenName ? (
          <p>
            {category.subcategory.childrenName} em{' '}
            <span className={styles['related-category']}>
              {category.subcategory.rootName}
            </span>
          </p>
        ) : (
          <p>{category.name}</p>
        )}

        <Image
          src={imgPrefix('/images/shared/arrow-forward.svg')}
          alt="Seta para próximo passo"
          width={24}
          height={24}
        />
      </label>
    </li>
  );
}
