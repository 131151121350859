import { forwardRef } from 'react';

import { z } from 'zod';

import { StepByStepProvider } from './context/StepByStepContext';

import { useScrollRef } from '../../../../hooks/useScrollRef';

import { StepByStep } from './components/StepByStep/StepByStep';

import { type ICategoryTreeRecord } from '../../../../modules/budget/transformations/form/@interfaces';

const schema = z.object({
  searchText: z.string().min(1),
});

export type TStepByStepFormSchema = z.infer<typeof schema>;

interface IStepByStepFormProps {
  categoryTree: ICategoryTreeRecord;
  onSelectedItem: (category: ICategoryTreeRecord) => void;
}

const StepByStepForm = forwardRef<HTMLFormElement, IStepByStepFormProps>(
  ({ categoryTree, onSelectedItem }, ref) => {
    const { scrollToTarget } = useScrollRef(ref);

    const formSteps = [categoryTree.children];

    return (
      <StepByStepProvider formSteps={formSteps}>
        <StepByStep
          categoryTree={categoryTree}
          onSelectedItem={onSelectedItem}
          scrollToTarget={scrollToTarget}
        />
      </StepByStepProvider>
    );
  }
);

StepByStepForm.displayName = 'StepByStepForm';

export default StepByStepForm;
