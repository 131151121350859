import { MdAccessTime } from 'react-icons/md';

import { Col, Row } from 'darrius';

import { useCountdown } from '../../../hooks/useCountdown';

import styles from './Countdown.module.scss';

import { type ICountdownProps } from './@interfaces';

const DEFAULT_TIME_IN_SECONDS = 10;

const Countdown = ({
  onIsCountdownOver,
  textToShow,
  timeToCountDown = DEFAULT_TIME_IN_SECONDS,
  isShouldResetCountdown = null,
}: ICountdownProps) => {
  const { shouldShowCountdown, countdownActiveTime } = useCountdown({
    initialCount: timeToCountDown,
    onCountdownIsOverExecute: onIsCountdownOver,
    isShouldResetCountdown,
  });

  return (
    <>
      {shouldShowCountdown && (
        <Row className="center-sm space-between">
          <Col>
            <div className={styles.countdown__container}>
              <MdAccessTime size={24} />
              <span>
                {textToShow.prefix} <strong>{countdownActiveTime}</strong>{' '}
                {textToShow.suffix}
              </span>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Countdown;
