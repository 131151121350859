import { Line, Row, Col } from 'darrius';

import styles from './AveragePrice.module.scss';

interface IAveragePriceProps {
  link: string;
  service: string;
  minimum: string;
  average: string;
  maximum: string;
}

const AveragePrice = ({
  link,
  service,
  average,
  minimum,
  maximum,
}: IAveragePriceProps) => {
  const ariaLabel = `Custo médio estimado de ${service}`;

  return (
    <a
      className={`average_price start-sm ${styles.average_price} `}
      href={link}
      aria-label={ariaLabel}
    >
      <p className={`average_price__title ${styles.average_price__title} `}>
        Custo médio estimado
      </p>

      <p
        className={`average_price__average_value ${styles.average_price__average_value} `}
      >
        {average}
      </p>

      <p
        className={`average_price__average_label ${styles.average_price__average_label} `}
      >
        {service}
      </p>

      <Line
        className={`average_price__separator ${styles.average_price__separator} `}
      />

      <Row>
        <Col className="col-sm-6">
          <p
            className={`average_price__minimum_label ${styles.average_price__minimum_label} `}
          >
            Menor custo
          </p>

          <p
            className={`average_price__minimum_value ${styles.average_price__minimum_value} `}
          >
            {minimum}
          </p>
        </Col>

        <Col className="col-sm-6">
          <p
            className={`average_price__maximum_label ${styles.average_price__maximum_label} `}
          >
            Maior custo
          </p>

          <p
            className={`average_price__maximum_value ${styles.average_price__maximum_value} `}
          >
            {maximum}
          </p>
        </Col>
      </Row>
    </a>
  );
};

export default AveragePrice;
