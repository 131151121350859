import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Line,
  Paragraph,
  Tag,
  Row,
  Col,
} from 'darrius';

import styles from './PriceRange.module.scss';

interface IPriceRangeProps {
  categorySlug: string;
  name: string;
  minimum: string;
  maximum: string;
  medium: string;
  slug?: string;
}

const PriceRange = ({
  categorySlug,
  name,
  minimum,
  maximum,
  medium,
  slug = '',
}: IPriceRangeProps) => (
  <div className="no-wrap">
    <Card>
      <CardHeader>
        <Tag className="action">{categorySlug}</Tag>
      </CardHeader>

      <CardBody>
        <a
          className={`price_range__link text-link regular-s ${styles.price_range__link} `}
          href={`/${categorySlug}/preco/${slug}`}
          target="_self"
        >
          <h2
            className={`price_range__title-small ${styles.price_range__title_small} `}
          >
            {name}
          </h2>
        </a>
      </CardBody>

      <CardBody>
        <Paragraph
          className={`price_range__title-big ${styles.price_range__title_big} `}
          size="4"
          text={medium}
        />

        <Paragraph size="4" text="CUSTO MÉDIO ESTIMADO" />
      </CardBody>

      <Line className="no-margins" />

      <CardFooter>
        <Row>
          <Col className="col-sm-6">
            <Paragraph size="4" text="MENOR CUSTO" />

            <Paragraph
              className={`price_range__title_medium ${styles.price_range__title_medium} `}
              size="4"
              text={minimum}
            />
          </Col>

          <Col className="col-sm-6">
            <Paragraph size="4" text="MAIOR CUSTO" />

            <Paragraph
              className={`price_range__title_medium ${styles.price_range__title_medium} `}
              size="4"
              text={maximum}
            />
          </Col>
        </Row>
      </CardFooter>
    </Card>
  </div>
);

export default PriceRange;
