import { Paragraph } from 'darrius';

import { ConfirmationBadge, FlatCard, FlatCardBody } from '../../atoms';

import styles from './SimilarRequest.module.scss';

interface IReviewProps {
  cliName: string;
  profession: string;
  text: string;
  link: string;
}

const Review = ({ cliName, profession, text, link }: IReviewProps) => (
  <a
    href={link}
    aria-label={`Pedido de ${profession}`}
    className={styles['similar-request']}
  >
    <FlatCard>
      <FlatCardBody>
        <Paragraph
          text={`<strong>${cliName}</strong> contratou <strong>${profession}</strong>`}
          size="4"
        />
        <Paragraph
          text={text}
          size={'4'}
          className={styles['similar-request__text']}
        />

        <ConfirmationBadge
          text="Pedido atendido"
          alt="Pedido atendido"
          size={24}
        />
      </FlatCardBody>
    </FlatCard>
  </a>
);

export default Review;
